import qs from 'qs'

import api from '../api'

export const getLightHistoricFromApi = ({ token, qc, factnum, tokenkey, isNewcoOrigin }) => {
  const params = qs.stringify({ qc, factnum }, { skipNulls: true })
  const headers = {}
  if (isNewcoOrigin) {
    headers['X-Access-Token'] = tokenkey
  }
  return api.get(`/invoice/hiselec/${token}?${params}`, { headers })
}

export const getGasHistoricFromApi = ({ token, qc, factnum, tokenkey, isNewcoOrigin }) => {
  const params = qs.stringify({ qc, factnum }, { skipNulls: true })
  const headers = {}
  if (isNewcoOrigin) {
    headers['X-Access-Token'] = tokenkey
  }
  return api.get(`/invoice/hisgas/${token}?${params}`, { headers })
}
