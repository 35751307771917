import qs from 'qs'

import api from '../api'

export const getInvoiceData = ({ token, qc, factnum, tokenkey, isNewcoOrigin }) => {
  const headers = {}
  let params = qs.stringify({ qc, factnum, tokenkey }, { skipNulls: true })

  if (isNewcoOrigin) {
    params = qs.stringify({ qc, factnum }, { skipNulls: true })
    headers['X-Access-Token'] = tokenkey
  }

  return api.get(`/invoice/${token}?${params}`, { headers })
}

export const getNewcoInvoiceData = ({ newcoQs }) => api.get(`/invoice/new-co/${newcoQs}`)
