export const CLIENT_TYPE = {
  COMPANY: 'company',
  USER: 'user',
}

export const LANGUAGE = {
  ES: 'es',
  Z1: 'ct',
  ZF: 'eu',
  ZH: 'gl',
}

export const SIEBEL_SLUGS = {
  ESTABLE: 'estable',
  PLANA: 'plana',
  CUOTA_PCF: 'cuota-pcf',
  REG_PCF: 'reg-pcf',
}

export const SECTOR_TYPE = {
  '04': 'Electricidad',
  '02': 'Gas',
}

export const PRODUCT_TYPE = {
  GAS: 'gas',
  ELE: 'electricity',
  DUAL: 'dual',
}

export const PRODUCT_TYPE_NEWCO = {
  PLANA: 'plana',
  PLANA_RD: 'plana_rd',
}

export const SLUG_WITH_DIF_DESC = []

export const CHART_TYPE = {
  PERCENTAGE: 'percentage',
  EUR: 'eur',
}

export const NATURGY_URL = 'https://www.naturgy.es/'

export const LOCK_PIE_DATE = new Date(2021, 8, 16)

export const SPLASH_ANIMATION_DURATION = 2100

export const AC_URL = 'https://areaprivada.naturgy.es/ovh-web/Login.gas'

export const CONTACT_PHONES = {
  phone_asistance: '900 40 80 80',
  phone_ele: '900 171 171',
  phone_gas: '900 750 750',
  phone_general: ['626 888 222', '900 100 251'],
}

export const NATURGY_ONLINE_PAYMENT_URL = 'https://www.naturgy.es/hogar/fnt_hogar/pago_de_facturas_online'

export const TYPE_CLIENT = {
  client: 'client',
  user: 'user',
}

export const OTHER_CHANNELS_CLIENT = {
  es: 'https://www.naturgy.es/hogar/ayuda/contacto',
  ct: 'https://www.naturgy.es/ca/llar/ajuda/contacte',
  gl: 'https://www.naturgy.es/gl/fogar/axuda/contacto',
  eu: 'https://www.naturgy.es/hogar/ayuda/contacto',
}

export const OTHER_CHANNELS_COMPANY = {
  es: 'https://www.naturgy.es/negocios_y_autonomos/ayuda/contacto',
  ct: 'https://www.naturgy.es/ca/negocis_i_autonoms/ajuda/contacte',
  gl: 'https://www.naturgy.es/gl/negocios_e_autonomos/axuda/contacto',
  eu: 'https://www.naturgy.es/negocios_y_autonomos/ayuda/contacto',
}

export const OTHER_CHANNELS_CLIENT_NEWCO = {
  es: 'https://cloud.mail.naturgy.es/contacta',
  ct: 'https://cloud.mail.naturgy.es/contacte',
  gl: 'https://cloud.mail.naturgy.es/contacto',
  eu: 'https://cloud.mail.naturgy.es/contacta',
}

export const SHOW_DUAL_IVA = false

export const ELECTRICITY = 'electricidad'
export const GAS = 'gas'
export const SERVICES = 'servicios'

export const ELEC = 'Electricidad'

export const SELF_CONSUMPTION_TYPES = ['10', '11', '20', '21']
