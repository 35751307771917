import { takeLatest, put, all, call, delay } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import Core from 'factint-core'
import { Types } from './constants'
import {
  setTokenSuccess,
  setInvoiceData,
  setQc,
  setIframeMode,
  setFacnum,
  setIsFetching,
  setQsSuccess,
  setTokenkey,
  setIsNewcoOrigin
} from './actions'
import { getProductBySlug } from '~/strapi/products'
import { getServiceBySlug } from '~/strapi/services'
import { getPromotionsBySlug } from '~/strapi/promotions'
import { getFinancingBySlug } from '~/strapi/financings'
import { getNotificationsBySlug } from '~/strapi/notifications'
import { SPLASH_ANIMATION_DURATION, LANGUAGE } from '~/config/constants'
import i18n from '~/config/i18n'
import { Cookies } from '~/config/cookies'
import { getInvoiceData, getNewcoInvoiceData } from '~/services/invoice'
import { getBatteryData } from '~/services/battery'

function* checkSplashAnimation(startTime) {
  const endTime = window.performance.now()
  const diffTime = endTime - startTime
  if (diffTime < SPLASH_ANIMATION_DURATION) {
    yield delay(SPLASH_ANIMATION_DURATION - diffTime)
  }
}

function* getServices(invoice) {
  const serIds = invoice.detalle_ser?.services?.length > 0 ? invoice.detalle_ser.services.map(ser => ser.id) : []
  const serList = serIds.length > 0 ? yield call(getServiceBySlug, serIds) : []
  const productSer =
    serIds.length > 0
      ? invoice.detalle_ser.services.map(ser => ({
          ...ser,
          product: serList.data.filter(s => s.idRdi === ser.id)[0],
        }))
      : []
  return productSer
}

function* getPromotions(invoice) {
  const promoIds =
    invoice.detalle_ser?.promotions?.length > 0 ? invoice.detalle_ser.promotions.map(promo => promo.id) : []
  const promoList = promoIds.length > 0 ? yield call(getPromotionsBySlug, promoIds) : []
  const productPromo =
    promoIds.length > 0
      ? invoice.detalle_ser.promotions.map(promo => ({
          ...promo,
          product: promoList.data.filter(p => p.idRdi === promo.id)[0],
        }))
      : []
  return productPromo
}

function* getFinancings(invoice) {
  const financingIds =
    invoice.detalle_ser?.financings?.length > 0 ? invoice.detalle_ser.financings.map(fin => fin.id) : []
  const financingList = financingIds?.length > 0 ? yield call(getFinancingBySlug, financingIds) : []
  const productFinancing =
    financingIds?.length > 0
      ? invoice.detalle_ser.financings.map(fin => ({
          ...fin,
          product: financingList.data.filter(f => f.idRdi === fin.id)[0],
        }))
      : []
  return productFinancing
}

function* getNotifications(invoice) {
  const notifications = invoice.modules
  const notificationIds = notifications.length > 0 ? notifications.map(notification => notification.slug) : []
  const notificationList = notificationIds.length > 0 ? yield call(getNotificationsBySlug, notificationIds) : []
  const messageNotification =
    notificationIds.length > 0
      ? notifications.map(notification => ({
          ...notification,
          message: notificationList.data.filter(n => n.slug === notification.slug)[0],
        }))
      : []

  return messageNotification
}

function* handleDataRdiData(data, lng) {
  let newData = data

  //Products
  const productEle = data.detalle_ele ? yield call(getProductBySlug, data.detalle_ele.producto_siebel) : null
  const productGas = data.detalle_gas ? yield call(getProductBySlug, data.detalle_gas.producto_siebel) : null

  // Services
  const productSer = yield getServices(data)

  // Promotions
  const productPromo = yield getPromotions(data)

  // Financings
  const productFinancing = yield getFinancings(data)

  // Notifications
  const productNotifications = yield getNotifications(data)

  // Language
  let activeLang = null
  if (lng && Object.values(LANGUAGE).some(l => l === lng)) {
    activeLang = lng
    i18n.changeLanguage(lng)
  } else {
    activeLang = LANGUAGE[newData.header.langu.toUpperCase()]
    i18n.changeLanguage(activeLang)
  }

  newData.header.langu = activeLang

  if (process.env.NODE_ENV !== 'production') {
    console.log({ rdiData: newData })
  }

  newData = {
    ...newData,
    detalle_ele: data.detalle_ele ? { ...data.detalle_ele, product: productEle?.data } : null,
    detalle_gas: data.detalle_gas ? { ...data.detalle_gas, product: productGas?.data } : null,
    detalle_ser: data.detalle_ser
      ? {
          ...data.detalle_ser,
          services: productSer,
          promotions: productPromo,
          financings: productFinancing,
        }
      : null,
    notifications: productNotifications,
    pieChart: data.chart,
  }

  return newData
}

export function* setAuthToken(action) {
  const startTime = window.performance.now()

  const { token, newcoQs, history, qc, iframe, factnum, lng, dni, tokenkey, isNewcoOrigin } = action.payload
  const iframeMode = iframe === 'true'

  Sentry.setContext('invoice', {
    lng,
    qc,
    factnum,
    qs: newcoQs,
    token,
    iframeMode,
    dni,
  })

  yield put(setIframeMode(iframeMode))

  if (!newcoQs && !token) {
    history.push('/error')
    return
  }

  yield put(setIsFetching(true))

  try {
    // Invoice
    let invoice = null

    if (token) {
      const { data } = yield call(getInvoiceData, { token, qc, factnum, tokenkey, isNewcoOrigin })
      const { data: battery, status } = yield call(getBatteryData, {
        token,
        tokenkey,
        factnum,
        supply: data?.header?.yevstelle_ext,
        nif: data?.header?.nif,
        isNewcoOrigin
      })

      const newData = yield handleDataRdiData(data, lng)

      invoice = Core.getInvoice({ rdiData: newData })

      invoice = { ...invoice, isRdi: true, battery: { ...battery, showBattery: status === 200 } }
    } else {
      i18n.changeLanguage(lng)
      const { data } = yield call(getNewcoInvoiceData, { newcoQs })
      const { data: battery, status } = yield call(getBatteryData, {
        token: newcoQs,
        tokenkey,
        factnum,
        supply: data?.header?.yevstelle_ext,
        nif: data?.header?.nif,
        isNewcoOrigin
      })

      invoice = Core.getInvoice({ newcoData: data })

      invoice = { ...invoice, isRdi: false, battery: { ...battery, showBattery: status === 200 } }
    }

    if (!invoice) {
      history.push('/error')
      return
    }

    yield all([
      token ? put(setTokenSuccess({ token })) : put(setQsSuccess({ qs: newcoQs })),
      put(setQc({ qc })),
      put(setFacnum({ factnum })),
      put(setInvoiceData({ invoice })),
      put(setIsNewcoOrigin({ isNewcoOrigin })),
      put(setTokenkey({ tokenkey }))
    ])

    yield checkSplashAnimation(startTime)

    if (!iframeMode && process.env.NODE_ENV !== 'development') {
      Cookies(window, document)
      yield delay(50)
    }

    yield put(setIsFetching(false))
    history.push('/home')
  } catch (err) {
    yield put(setIsFetching(false))
    history.push({
      pathname: '/error',
      state: { type: err?.response?.status, isRdi: !!token },
    })
  }
}

export default all([takeLatest(Types.SET_TOKEN, setAuthToken), takeLatest(Types.SET_QS, setAuthToken)])
