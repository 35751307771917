import qs from 'qs'

import { apiBattery } from '../api'

export const getBatteryData = ({ token, tokenkey, factnum, supply, nif, isNewcoOrigin }) => {
  const params = qs.stringify({ invoice: factnum, supply }, { skipNulls: true })
  const headers = {
    nif,
    tokenRDI: token
  }

  if (isNewcoOrigin) {
    delete headers.tokenRDI
    headers['X-Access-Token'] = tokenkey
  }

  return apiBattery.get(`/bateria/user?${params}`, {
    validateStatus: () => true,
    headers,
  })
}
