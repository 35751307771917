import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Text } from '~/components/atoms'
import * as S from './styled'
import { SELF_CONSUMPTION_TYPES } from '~/config/constants'

/**
  Uso:

  <ProductSeparatosSection type={10} />
*/

const ProductSeparatosSection = ({ type }) => {
  const { t } = useTranslation()
  if (!SELF_CONSUMPTION_TYPES.includes(type.toString()) || !type) return null
  return (
    <S.EcoContainer>
      <Text fontSize='16px' fontType='semibold'>
        {t(`self_consumption_types_${type}`)}
      </Text>
    </S.EcoContainer>
  )
}

ProductSeparatosSection.defaultProps = { type: '' }

ProductSeparatosSection.propTypes = { type: PropTypes.string }

export default ProductSeparatosSection
