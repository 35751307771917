import { Types } from './constants'

export const setToken = payload => ({
  type: Types.SET_TOKEN,
  payload,
})

export const setQs = payload => ({
  type: Types.SET_QS,
  payload,
})

export const setTokenSuccess = payload => ({
  type: Types.SET_TOKEN_SUCCESS,
  payload,
})

export const setTokenkey = payload => ({
  type: Types.SET_TOKENKEY,
  payload,
})

export const setIsNewcoOrigin = payload => ({
  type: Types.SET_IS_NEWCO_ORIGIN,
  payload,
})

export const setClientData = payload => ({
  type: Types.SET_CLIENT_DATA,
  payload,
})

export const setQsSuccess = payload => ({
  type: Types.SET_QS_SUCCESS,
  payload,
})

export const setContacts = payload => ({
  type: Types.SET_CONTACTS,
  payload,
})

export const setInvoiceData = payload => ({
  type: Types.SET_INVOICE_DATA,
  payload,
})

export const setSummaryData = payload => ({
  type: Types.SET_SUMMARY_DATA,
  payload,
})

export const setNotificationsData = payload => ({
  type: Types.SET_NOTIFICATIONS_DATA,
  payload,
})

export const setDiscountsData = payload => ({
  type: Types.SET_DISCOUNTS_DATA,
  payload,
})

export const setAdjustmentsData = payload => ({
  type: Types.SET_ADJUSTMENTS_DATA,
  payload,
})

export const setTaxesData = payload => ({
  type: Types.SET_TAXES_DATA,
  payload,
})

export const setQc = payload => ({
  type: Types.SET_QC,
  payload,
})

export const setIframeMode = payload => ({
  type: Types.SET_IFRAME_MODE,
  payload,
})

export const setFacnum = payload => ({
  type: Types.SET_FACNUM,
  payload,
})

export const setIsFetching = payload => ({
  type: Types.SET_IS_FETCHING,
  payload,
})
