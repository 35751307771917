export const Types = {
  SET_TOKEN: '@auth/SET_TOKEN',
  SET_QS: '@auth/SET_QS',
  SET_TOKEN_SUCCESS: '@auth/SET_TOKEN_SUCCESS',
  SET_IS_NEWCO_ORIGIN: '@auth/SET_IS_NEWCO_ORIGIN',
  SET_TOKENKEY: '@auth/SET_TOKENKEY',
  SET_QS_SUCCESS: '@auth/SET_QS_SUCCESS',
  SET_CLIENT_DATA: '@auth/SET_CLIENT_DATA',
  SET_INVOICE_DATA: '@auth/SET_INVOICE_DATA',
  SET_SUMMARY_DATA: '@auth/SET_SUMMARY_DATA',
  SET_CONTACTS: '@auth/SET_CONTACTS',
  SET_NOTIFICATIONS_DATA: '@auth/SET_NOTIFICATIONS_DATA',
  SET_DISCOUNTS_DATA: '@auth/SET_DISCOUNTS_DATA',
  SET_ADJUSTMENTS_DATA: '@auth/SET_ADJUSTMENTS_DATA',
  SET_TAXES_DATA: '@auth/SET_TAXES_DATA',
  SET_QC: '@auth/SET_QC',
  SET_IFRAME_MODE: '@auth/SET_IFRAME_MODE',
  SET_FACNUM: '@auth/SET_FACNUM',
  SET_IS_FETCHING: '@auth/SET_IS_FETCHING',
}
